.Navbar {
  border-top: 5px solid $grey-color-dark;
  border-bottom: 1px solid $grey-color-light;
  min-height: $spacing-unit * 1.865;

  position: relative;
  
  &__imgDailyprog {
    height: 50px;
    margin: .25em;

    @media screen and (min-width:640px) {
      height: 50px;
      margin: .25em;
      width: 15%;
    }
  }

  &__img {
    display: none;

    @media screen and (min-width: 640px) {
      display: inline-block;
      height: 48px;
      width: 44%;
      margin: .25em;
    }
  }

  &__menu {
    float: right;
    line-height: $base-line-height * $base-font-size * 2.25;

    @media screen and (max-width: 640px) {
      position: absolute;
      top: 9px;
      right: $spacing-unit / 2;
      background-color: $background-color;
      border: 1px solid $grey-color-light;
      border-radius: 5px;
      text-align: right;

      label[for="nav-trigger"] {
        display: block;
        float: right;
        width: 36px;
        height: 36px;
        z-index: 2;
        cursor: pointer;
      }

      input ~ .trigger {
        clear: both;
        display: none;
      }

      input:checked ~ .trigger {
        display: block;
        padding-bottom: 5px;
      }
    }
  }

  &__trigger {
    display: none;
  }

  &__menu-icon {
    display: none;

    @media screen and (max-width: 640px) {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }
  }

  &__link {
    color: $text-color;
    line-height: $base-line-height;

    &:not(:last-child) {
      margin-right: 20px;

      @media screen and (max-width: 640px) {
        margin-left: 20px;
        margin-right: 0;
      }
    }

    @media screen and (max-width: 640px) {
      display: block;
      padding: 5px 10px;
    }
  }
}
