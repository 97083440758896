.Footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  &__wrapper {
    display: block;
    width: 100%;
    margin: auto;

    @media screen and (min-width: 740px) {
      max-width: 740px;
    }
  }

  &__column {
    flex: 100%;
    padding: 1em;

    @media screen and (min-width: 576px) {
      flex: 1;
    }
  }

  &__site-title {
  }

  &__tagline {
    color: #828282;
  }

  &__email {
  }

  &__social {
    text-align: center;

    @media screen and (min-width: 576px) {
      text-align: right;
    }
  }
}
